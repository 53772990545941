var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"col-12 col-md-7"},[_c('CRow',[_c('CCol',{attrs:{"col":""}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Estado de suscripción")])]),_c('CCardBody',[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Seleccione una suscripción")]),_c('select',{staticClass:"form-control",on:{"change":function($event){return _vm.changeSuscription($event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("--Seleccionar--")]),_vm._l((_vm.suscripciones),function(item,index){return _c('option',{key:item.id,domProps:{"value":index + 1}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.nameSuscription)+" "),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"https://coreui.io/vue/docs/components/card-components","rel":"noreferrer noopener","target":"_blank"}})])]),_c('CCardBody',{staticClass:"p-4"},[(_vm.suscriptionSelected.name)?_c('div',{staticClass:"col-md-6 container__detalle"},[(
                      _vm.suscriptionSelected.price != '' &&
                      _vm.suscriptionSelected.price != null &&
                      _vm.suscriptionSelected.price != undefined
                    )?_c('p',[_c('strong',[_vm._v("Pago: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.price)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.periodo != '' &&
                      _vm.suscriptionSelected.periodo != null &&
                      _vm.suscriptionSelected.periodo != undefined
                    )?_c('p',[_c('strong',[_vm._v("Periodo: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.periodo)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.nextPaymentAmount != '' &&
                      _vm.suscriptionSelected.nextPaymentAmount != null &&
                      _vm.suscriptionSelected.nextPaymentAmount != undefined
                    )?_c('p',[_c('strong',[_vm._v("Importe de pago pendiente: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.nextPaymentAmount)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.deliveryDays != '' &&
                      _vm.suscriptionSelected.deliveryDays != null &&
                      _vm.suscriptionSelected.deliveryDays != undefined
                    )?_c('p',[_c('strong',[_vm._v("Dias de entrega: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.deliveryDays)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.fechasiguienterenovacion != '' &&
                      _vm.suscriptionSelected.fechasiguienterenovacion != null &&
                      _vm.suscriptionSelected.fechasiguienterenovacion !=
                        undefined
                    )?_c('p',[_c('strong',[_vm._v("Fecha de siguiente renovación: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.fechasiguienterenovacion.split( " " )[0])+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.lastPaymentDate != '' &&
                      _vm.suscriptionSelected.lastPaymentDate !=
                        null &&
                      _vm.suscriptionSelected.lastPaymentDate !=
                        undefined
                    )?_c('p',[_c('strong',[_vm._v("Fecha de último pago cancelado: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.lastPaymentDate.split( " " )[0])+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.importeultimopagocancelado != '' &&
                      _vm.suscriptionSelected.importeultimopagocancelado !=
                        null &&
                      _vm.suscriptionSelected.importeultimopagocancelado !=
                        undefined
                    )?_c('p',[_c('strong',[_vm._v("Importe del último pago cancelado: ")]),_vm._v(_vm._s(_vm.suscriptionSelected.importeultimopagocancelado)+" ")]):_vm._e(),(
                      _vm.suscriptionSelected.landing.category == 'print' ||
                      _vm.suscriptionSelected.landing.category == 'bundle'
                    )?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.firstName))]),_vm._v(" .Gracias por usar nuestro servicio ")])]):_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.firstName))]),_vm._v(", para mayor información de tu suscripción digital. !RECUERDA¡ ingresar a. ")]),_c('a',{attrs:{"href":"https://www.elcomercio.pe/suscripcionesdigitales"}},[_vm._v("elcomercio.pe/suscripcionesdigitales")])])]):_vm._e()])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"col-12 col-md-5 d-flex justify-content-center align-items-center"},[(_vm.flagImg == 1)?_c('img',{staticClass:"avatar__alicia-pq",attrs:{"src":require("../../assets/img/nueva_alicia_pages_all.png")}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }