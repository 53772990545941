<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.c-main {
  overflow: hidden;
}

.btn__modal {
  background: transparent;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid;
}
.btn__modal:hover {
  background: #000;
  color: #fff;
}

.avatar__alicia-pq {
  height: 55vh;
  margin-top: 20vh;
}

.container-alicia-pq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.pq {
  width: 17em;
  height: 15em;
  background: #4b92fc;
  border-radius: 100%;
  padding: 36px 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;

  span {
    font-size: 2em;
    font-weight: bold;
  }
  p {
    color: #fff;
  }
}

.pq .triangulo-pq {
  width: 0;
  height: 0;
  border-left: 48px solid #4b92fc;
  border-top: 12px solid transparent;
  border-bottom: 47px solid transparent;
  position: relative;
  top: -5vh;
  left: 11vw;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  z-index: -1;
}

.card {
  margin-bottom: 0px !important;
}

.text-content-modal {
  font-size: 1em;
}

@media only screen and (max-width: 575px) {
  .avatar__alicia-pq {
    height: 45vh;
    margin-top: 13vh;
  }

  .title__section {
    font-size: 0.8em;
  }

  .pq {
    width: 12em;
    height: 11em;
    padding: 1.2em 2em;
  }

  .pq p {
    font-size: 0.8em;
  }

  .pq span {
    font-size: 1.2em;
  }

  .pq .triangulo-pq {
    top: -3vh;
    left: 26vw;
  }

  .modal.show .modal-dialog {
    max-width: 80% !important;
  }

  .text-content-modal {
    font-size: 0.8em;
    text-align: justify;
  }

  .btn__modal {
    padding: 4px 8px;
    font-size: 0.8em;
  }
}
</style>

<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <div class="col-12 col-md-7">
      <CRow>
        <CCol col>
          <CCard>
            <CCardHeader>
              <strong>Estado de suscripción</strong>
            </CCardHeader>
            <CCardBody>
              <div class="form-group col-md-10">
                <label for="">Seleccione una suscripción</label>
                <select
                  class="form-control"
                  @change="changeSuscription($event)"
                >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in suscripciones"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <CCard>
                <CCardHeader>
                  {{ nameSuscription }}
                  <div class="card-header-actions">
                    <a
                      href="https://coreui.io/vue/docs/components/card-components"
                      class="card-header-action"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                    </a>
                  </div>
                </CCardHeader>
                <CCardBody class="p-4">
                  <div
                    v-if="suscriptionSelected.name"
                    class="col-md-6 container__detalle"
                  >
                    <p
                      v-if="
                        suscriptionSelected.price != '' &&
                        suscriptionSelected.price != null &&
                        suscriptionSelected.price != undefined
                      "
                    >
                      <strong>Pago: </strong>{{ suscriptionSelected.price }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.periodo != '' &&
                        suscriptionSelected.periodo != null &&
                        suscriptionSelected.periodo != undefined
                      "
                    >
                      <strong>Periodo: </strong
                      >{{ suscriptionSelected.periodo }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.nextPaymentAmount != '' &&
                        suscriptionSelected.nextPaymentAmount != null &&
                        suscriptionSelected.nextPaymentAmount != undefined
                      "
                    >
                      <strong>Importe de pago pendiente: </strong
                      >{{ suscriptionSelected.nextPaymentAmount }}
                    </p>
                    <p
                      v-if="
                        suscriptionSelected.deliveryDays != '' &&
                        suscriptionSelected.deliveryDays != null &&
                        suscriptionSelected.deliveryDays != undefined
                      "
                    >
                      <strong>Dias de entrega: </strong
                      >{{ suscriptionSelected.deliveryDays }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.fechasiguienterenovacion != '' &&
                        suscriptionSelected.fechasiguienterenovacion != null &&
                        suscriptionSelected.fechasiguienterenovacion !=
                          undefined
                      "
                    >
                      <strong>Fecha de siguiente renovación: </strong
                      >{{
                        suscriptionSelected.fechasiguienterenovacion.split(
                          " "
                        )[0]
                      }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.lastPaymentDate != '' &&
                        suscriptionSelected.lastPaymentDate !=
                          null &&
                        suscriptionSelected.lastPaymentDate !=
                          undefined
                      "
                    >
                      <strong>Fecha de último pago cancelado: </strong
                      >{{
                        suscriptionSelected.lastPaymentDate.split(
                          " "
                        )[0]
                      }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.importeultimopagocancelado != '' &&
                        suscriptionSelected.importeultimopagocancelado !=
                          null &&
                        suscriptionSelected.importeultimopagocancelado !=
                          undefined
                      "
                    >
                      <strong>Importe del último pago cancelado: </strong
                      >{{ suscriptionSelected.importeultimopagocancelado }}
                    </p>

                    <div
                      v-if="
                        suscriptionSelected.landing.category == 'print' ||
                        suscriptionSelected.landing.category == 'bundle'
                      "
                    >
                      <p>
                        <b>{{ firstName }}</b> .Gracias por usar nuestro
                        servicio
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        <b>{{ firstName }}</b
                        >, para mayor información de tu suscripción digital.
                        !RECUERDA¡ ingresar a.
                      </p>
                      <a href="https://www.elcomercio.pe/suscripcionesdigitales"
                        >elcomercio.pe/suscripcionesdigitales</a
                      >
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <div class="col-12 col-md-5 d-flex justify-content-center align-items-center">
      <img
        class="avatar__alicia-pq"
        v-if="flagImg == 1"
        src="../../assets/img/nueva_alicia_pages_all.png"
      />
      <!--<img class="avatar__alicia__mobile-pq" src="../../assets/img/alicia_3.jpg" />-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      arrOpcion: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Si actualizar mi correo de acceso" },
        { id: "2", name: "No conservar mi correo de acceso" },
      ],
      show: true,
      firstName: localStorage.getItem("name").split(" ")[0],
      isLoading: false,
      isNewCorreoPeruQuiosco: false,
      isObservacion: false,
      NewCorreoPeruQuiosco: "",
      observacion: "",
      positionId: "",
      emailVerificado: "",
      opcionCambiar: "",
      fullPage: true,
      flagImg: 1,
    };
  },
  components: {
    Loading,
  },
  methods: {
    listSubscriptions() {
      let vm = this;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      let data = {
        document_number: localStorage.getItem("numeroDocumento"),
        document_type: localStorage.getItem("tipoDocumento"),
        optionQuery: "2",
        queryType: "1",
        optionProcess: "1",
        channel: "7",
      };
      console.log("Send Data => soy hiro");
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/listSuscripChatbot`, data)
        .then(function (res) {
          vm.isLoading = false;

          if (res.data.data.code == 2) {
            vm.isLoading = false;
            vm.suscripciones = [res.data.data];
            vm.emailVerificado = "";

            vm.$fire({
              title: "Felicidades",
              text: `${res.data.data.message}`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "Seguridad",
              text: "Usted no tiene suscripciones",
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.suscripciones = [];
          }
        })
        .catch((err) => {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: "Ocurrio un error en el servidor",
            type: "error",
            timer: 3000,
          }).then((r) => {});
        });
    },
    async changeSuscription(event) {
      let vm = this;
      vm.positionId = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (vm.positionId == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;
        let data = {
          position_id: vm.positionId,
          consulta: "1",
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
          queryType: "1",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/consultaPaqueteChatbot`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        //console.log("response",response);

        if (response.code == 2) {
          vm.isLoading = false;
          vm.suscriptionSelected = response;
          if (vm.suscriptionSelected.name) {
            vm.isLoading = false;
            vm.$fire({
              title: "Enhorabuena",
              text: `Suscripción encontrada`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
            vm.nameSuscription = `La suscripción que ha seleccionado es: ${vm.suscriptionSelected.name}`;
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "info",
              text: `Suscripción no encontrada`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.nameSuscription = `Aún no ha seleccionado una suscripción`;
          }
        }
      }
    },
    showImg() {
      this.flagImg = 1;
    },
    hideImg() {
      this.flagImg = 0;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    vm.listSubscriptions();
  },
};
</script>
